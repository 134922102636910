import { IChat2ChartInfor } from "types/chat2Chart";
import { IDataQualityParam } from "types/dataQuality";
import { axiosNoAuthInstance } from "utils/axios";

export async function sendMsg(item: IDataQualityParam) {
  const endpoint = `https://ivy-analytics-c3bje6gnhwcbgvdq.southeastasia-01.azurewebsites.net/api/v1/data-quality/agent/chat`;

  const response = await axiosNoAuthInstance.post(endpoint, item);
  const data = response?.data;

  return data as IChat2ChartInfor;
}