import { ILoginResponse } from 'types/auth';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';


interface IAuthState {
  user: ILoginResponse | null;
  // isAuthenticated: boolean;
  setUser: (user: ILoginResponse | null) => void;
  resetUser: () => void;
  userId: string | null;
  hasSetting: boolean;
  setUserId: (userId: string) => void;
  setHasSetting: (hasSetting: boolean) => void;
}

export const useAuthStore = create<IAuthState>()(
  devtools(
    persist(
      (set) => ({
        user: null,
        // isAuthenticated: false,
        setUser: (user) => set({ user }),
        resetUser: () => set({ user: null }),
        userId: null,
        hasSetting: false,
        setUserId: (userId) => set({ userId }),
        setHasSetting: (hasSetting) => set({ hasSetting }),
      }),
      {
        name: 'auth-storage',
      },
    ),
  ),
);
