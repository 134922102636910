import { DatabaseConfig } from "pages/Visualizer/types";
import { axiosNoAuthInstance } from "utils/axios";

const url = process.env.REACT_APP_API_MODELING;

export async function getModeling(userId: string): Promise<DatabaseConfig> {
  const endpoint = `${url}/model/${userId}`;

  const response = await axiosNoAuthInstance.get(endpoint);

  return response?.data as DatabaseConfig;
}