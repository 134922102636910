// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.has-many-edge {
  /* https://reactflow.dev/docs/guides/theming/#react-flow-class-names */
}
.has-many-edge.selected path.react-flow__edge-path {
  marker-end: url(#hasManySelected);
  stroke-width: 1.5;
}
.has-many-edge--highlighted path.react-flow__edge-path,
.has-many-edge--highlighted path.react-flow__edge-interaction,
.has-many-edge--highlighted path.react-flow__connection-path {
  stroke: #2186EB !important;
  stroke-width: 1.5px;
}

.has-many-edge-reversed.selected path.react-flow__edge-path {
  marker-end: url(#hasManyReversedSelected);
  stroke-width: 1.5;
}
.has-many-edge-reversed--highlighted path.react-flow__edge-path,
.has-many-edge-reversed--highlighted path.react-flow__edge-interaction,
.has-many-edge-reversed--highlighted path.react-flow__connection-path {
  stroke: #2186EB !important;
  stroke-width: 1.5px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Visualizer/style/has-many-edge.scss"],"names":[],"mappings":"AAAA;EACA,sEAAA;AACA;AAAE;EACE,iCAAA;EACA,iBAAA;AAEJ;AAEI;;;EAGE,0BAAA;EACA,mBAAA;AAAN;;AAME;EACE,yCAAA;EACA,iBAAA;AAHJ;AAOI;;;EAGE,0BAAA;EACA,mBAAA;AALN","sourcesContent":[".has-many-edge {\r\n/* https://reactflow.dev/docs/guides/theming/#react-flow-class-names */\r\n  &.selected path.react-flow__edge-path {\r\n    marker-end: url(#hasManySelected);\r\n    stroke-width: 1.5;\r\n  }\r\n\r\n  &--highlighted {\r\n    path.react-flow__edge-path,\r\n    path.react-flow__edge-interaction,\r\n    path.react-flow__connection-path {\r\n      stroke: #2186EB !important;\r\n      stroke-width: 1.5px;\r\n    }\r\n  }\r\n}\r\n\r\n.has-many-edge-reversed {\r\n  &.selected path.react-flow__edge-path {\r\n    marker-end: url(#hasManyReversedSelected);\r\n    stroke-width: 1.5;\r\n  }\r\n\r\n  &--highlighted {\r\n    path.react-flow__edge-path,\r\n    path.react-flow__edge-interaction,\r\n    path.react-flow__connection-path {\r\n      stroke: #2186EB !important;\r\n      stroke-width: 1.5px;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
