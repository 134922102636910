import styled from "styled-components";

export const Chat2ChartWrapper = styled.div`
  > .title {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    text-align: left;
    color: #141414;
    padding: 10px 20px;
    border-bottom: 1px solid #d9d9d9;
  }

  > .body {
    display: grid;
    grid-template-columns: 40% 60%;
  }
  .detail-conversation {
    padding: 20px;
    height: calc(100vh - 170px);
    overflow: auto;
  }
`;
export const ChatBotContentWrap = styled.div`
  width: 100%;
  margin: auto;
`;

export const ChatBotWrap = styled.div`
  background: #fff;
  width: 460px;
  overflow: hidden;
  width: 100%;
  border-left: 1px solid #d9d9d9;
`;

export const Title = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  padding: 12px;
  border-radius: 8px;
  background-color: #cbf6ee;
`;

export const DataItemWrap = styled.div`
  border-bottom: 1px solid #f5efff;
  .col {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    .col-name {
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      text-align: left;
    }
    > span {
      padding: 16px 12px;
    }
    .total-errors {
      text-align: right;
    }
    .action {
      text-align: right;
    }
  }
  .analysis {
    background: #f5f5f5;
    border-bottom: 1px solid #fff;
    display: grid;
    grid-template-columns: 10% 40% 16% 16% 18%;
    > div {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      padding: 8px 12px;
    }
  }
`;
