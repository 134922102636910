import { ItemChart } from "components/ItemChart";
import { useEffect, useState } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import { useParams } from "react-router-dom";
import { getDashboardById } from "services/dashboard";
import { IChartItem } from "types/chat2Insight";
import { ViewDashboardWrap } from "./style";

const ResponsiveGridLayout = WidthProvider(Responsive);

export function ViewDashboard() {
  const [layouts, setLayout] = useState<IChartItem[]>([]);
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const { id, userId } = useParams();

  const getDashboarDetail = async () => {
    try {
      if (!userId || !id) return;
      const dashboard = await getDashboardById(userId, id);
      setTitle(dashboard.name);
      setDescription(dashboard.description);
      setLayout(dashboard.charts || []);
    } catch (error) {}
  };

  useEffect(() => {
    getDashboarDetail();
  }, []);

  return (
    <ViewDashboardWrap>
      <div className="title">{title}</div>
      <div className="description">{description}</div>
      <ResponsiveGridLayout
        isDraggable={false}
        isResizable={false}
        breakpoints={{
          lg: 1200,
          md: 996,
          sm: 768,
          xs: 480,
          xxs: 0,
        }}
        cols={{ lg: 12, md: 8, sm: 4, xs: 4, xxs: 4 }}
        // onLayoutChange={onLayoutChange}
        // onDrop={onDrop}
        style={{ minHeight: "700px" }}
        rowHeight={100}
        // onBreakpointChange={handleBreakPointChange}
      >
        {layouts.map((item, index) => (
          <div data-grid={item} key={item.i}>
            <ItemChart
              table_json={item?.table_json}
              sql_result=""
              chartjs_json={item.chartjs_json}
              // callBack={() => removeItem(item.i)}
              // icon={<CloseOutlined />}
            />
          </div>
        ))}
      </ResponsiveGridLayout>
    </ViewDashboardWrap>
  );
}
