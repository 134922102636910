import { IDataQualityReport } from "types/dataQuality";
import { Title } from "../style";
import { DataItem } from "./DataItem";

export const DataReportItem = ({
  data,
  isBefore,
}: {
  data?: IDataQualityReport[];
  isBefore?: boolean;
}) => {
  const title = isBefore
    ? "Quality report of Original"
    : "Quality report after update";
  return (
    <>
      {data && <Title>{title}</Title>}
      {data?.map((item) => (
        <DataItem item={item} />
      ))}
    </>
  );
};
