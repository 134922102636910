// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-flow__node-custom {
  border: 1px solid #555;
  padding: 10px;
  width: 300px;
  border-radius: 5px;
}
.react-flow__edge.selected .react-flow__edge-path {
  stroke: #2186EB !important;
}
.react-flow__node {
  max-width: 288px;
  background-color: #F5F7FA;
}
.react-flow__attribution a {
  background: none;
}
.react-flow__handle-left {
  border: 0 !important;
  background: transparent !important;
}
.react-flow__handle-right {
  border: 0 !important;
  background: transparent !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/Visualizer/style/react-flow.scss"],"names":[],"mappings":"AACE;EACE,sBAAA;EACA,aAAA;EACA,YAAA;EACA,kBAAA;AAAJ;AAGE;EACE,0BAAA;AADJ;AAIE;EACE,gBAAA;EAEA,yBAAA;AAHJ;AAME;EACE,gBAAA;AAJJ;AAOE;EACE,oBAAA;EAEA,kCAAA;AANJ;AASE;EACE,oBAAA;EAEA,kCAAA;AARJ","sourcesContent":[".react-flow {\r\n  &__node-custom {\r\n    border: 1px solid #555;\r\n    padding: 10px;\r\n    width: 300px;\r\n    border-radius: 5px;\r\n  }\r\n\r\n  &__edge.selected &__edge-path {\r\n    stroke: #2186EB !important;\r\n  }\r\n\r\n  &__node {\r\n    max-width: 288px;\r\n\r\n    background-color: #F5F7FA;\r\n  }\r\n\r\n  &__attribution a {\r\n    background: none;\r\n  }\r\n\r\n  &__handle-left {\r\n    border: 0 !important;\r\n\r\n    background: transparent !important;\r\n  }\r\n\r\n  &__handle-right {\r\n    border: 0 !important;\r\n\r\n    background: transparent !important;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
