import styled from "styled-components";

export const ItemWrapper = styled.div`
  height: 100%;
  border-radius: 16px;
  padding: 10px 20px;
  box-shadow: 6px 8px 4px 3px #01010114;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: end;
  position: relative;
  .remove {
    cursor: pointer;
    color: rgb(245, 34, 45);
    font-weight: 700;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .chart-title {
    text-align: center;
    font-weight: 700;
    padding: 0px 20px;
    color: #000;
    font-family: "Open Sans", sans-serif;
  }
  .chart-content {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 300px;
    canvas {
      /* height: 100% !important; */
    }
  }
  .selected-view {
    cursor: pointer;
    padding: 4px 16px;
    display: inline-block;
    margin: 0 4px;
    border-radius: 100px;
  }
`;

export const CustomTable = styled.div`
  /* max-width: unset; */
  /* .ant-table-header {
    height: 17%;
  }
  .ant-table-container,
  .ant-table,
  .ant-spin-container,
  .ant-spin-nested-loading,
  &.ant-table-wrapper {
    height: 100%;
  } */
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-body
    > table
    > tbody
    > tr
    > td,
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table
    > thead
    > tr
    > th {
    overflow-wrap: normal;
  }
`;
