import styled from "styled-components";

export const ChatBotWrap = styled.div`
  border-radius: 16px 16px 0px 16px;
  background: #f5f5f5;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-shadow: 0px 3px 36px 0px #0000001a;
  box-shadow: 0px 0px 4px 0px #00000029;
`;

export const ChatBotHeader = styled.div`
  padding: 8px 20px;
  background-color: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ChatBotBody = styled.div`
  height: calc(100% - 122px);
  padding: 20px;
  overflow: auto;
`;

export const MsgReaction = styled.div`
  display: none;
  position: absolute;
  right: 12px;
  bottom: -17px;
  z-index: 1;
  .result-reaction {
    display: flex;
    background-color: #f9f0ff;
    padding: 2px;
    border-radius: 100%;
    box-shadow: 0px 2px 6px 0px #17003e1a;
  }
  .pick-reaction {
    background-color: #f9f0ff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    padding: 4px 12px;
    box-shadow: 0px 2px 6px 0px #17003e1a;
  }
  .like-icon {
    margin-right: 6px;
  }
  .like-icon,
  .dislike-icon {
    display: flex;
    cursor: pointer;
    & > span:nth-child(1) {
      display: flex;
    }
    & > span:nth-child(2) {
      display: none;
    }
  }
  .like-icon:hover,
  .dislike-icon:hover {
    & > span:nth-child(1) {
      display: none;
    }
    & > span:nth-child(2) {
      display: flex;
    }
  }
`;

export const ChatBotMessageReceive = styled.div`
  display: flex;
  margin-bottom: 8px;
  align-items: flex-end;
  &:hover ${MsgReaction} {
    display: block;
  }
  .content {
    margin-left: 8px;
    background: #f0f0f0;
    color: #262626;
    padding: 20px 24px;
    line-height: 22px;
    border-radius: 12px;
    max-width: 80%;
    position: relative;
    .markdown {
      overflow: auto;
      * {
        font-size: 16px;
      }
    }
  }
  img {
    max-width: 100% !important;
  }

  .markdown table th {
    font-weight: 600;
    word-break: keep-all;
  }

  .markdown table th,
  .markdown table td {
    padding: 6px 13px;
    border: 1px solid #d1d9e0;
  }

  .markdown table td > :last-child {
    margin-bottom: 0;
  }

  .markdown table tr {
    background-color: #ffffff;
    border-top: 1px solid  #f6f8fa;
  }

  .markdown table tr:nth-child(even), .markdown table thead tr {
    background-color: #f6f8fa;
  }
`;

export const ChatBotMessageSend = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
  align-items: flex-end;
  flex-direction: column;

  .content {
    color: #141414;
    background: linear-gradient(270deg, #c4efe4 0%, #cdf7f0 100%);
    padding: 20px 24px;
    line-height: 22px;
    border-radius: 12px;
    max-width: 80%;
    * {
      color: white;
    }
  }
  .error {
    margin-top: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #f5222d;
  }

  img {
    max-width: 100% !important;
  }
`;

export const ChatBotFooter = styled.div`
  height: 66px;
  border-top: 1px solid #f0f0f0;
  background-color: #fff;
  padding: 11px 20px;
  position: relative;
  display: flex;
  align-items: center;
  .message-options {
    left: 0px;
    position: absolute;
    bottom: 66px;
    width: 100%;
    background: #fafafa;
    padding: 8px 20px 2px;
    .message {
      background: #f9f0ff;
      border: 1px solid #ffffff;
      padding: 8px 12px;
      border-radius: 50px;
      color: #213992;
      width: auto;
      display: inline-block;
      cursor: pointer;
      margin-right: 6px;
      margin-bottom: 6px;
      box-shadow: 0px 1px 6px 0px #2506581a;
      &.active {
        background: #efdbff;
      }
    }
  }
  input {
    width: 100%;
    border: none;
    &:active,
    &:focus {
      border: none !important;
      box-shadow: none !important;
    }
  }
  button {
    box-shadow: none !important;
    border: none !important;
    background-color: transparent;
  }
`;

export const TypingMsg = styled.div`
  .lds-ellipsis {
    /* change color here */
    color: #3fa684;
  }
  .lds-ellipsis,
  .lds-ellipsis div {
    box-sizing: border-box;
  }
  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 50px;
    height: 6px;
  }
  .lds-ellipsis div {
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: currentColor;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 2px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 2px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 16px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 32px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
`;
