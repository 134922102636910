import { IChartData } from "types/chat2Insight";
import {
  IChartParam,
  IDashboard,
  IDashboardParam,
  IListDashboard,
} from "types/dashboard";
import { axiosNoAuthInstance } from "utils/axios";

// const url =
//   "https://dashboard-service.happyforest-0c743beb.southeastasia.azurecontainerapps.io";

const url = process.env.REACT_APP_API_DASHBOARD;

export async function saveChart(data: IChartParam) {
  const endpoint = `${url}/chart`;

  const response = await axiosNoAuthInstance.post(endpoint, data);

  return response.data.data;
}

export async function getCharts(userId: string) {
  const endpoint = `${url}/chart/${userId}`;

  const response = await axiosNoAuthInstance.get(endpoint);

  console.log("response", response);

  return response.data as IChartData[];
}

export async function createDashboard(data: IDashboardParam) {
  const endpoint = `${url}/dashboard`;

  const response = await axiosNoAuthInstance.post(endpoint, data);
  console.log(response);

  return response.data;
}

export async function updateDashboard(data: IDashboardParam) {
  const endpoint = `${url}/dashboard`;

  const response = await axiosNoAuthInstance.put(endpoint, data);
  console.log(response);

  return response.data;
}

export async function refreshDashboard(
  userId: string,
  projectId: string,
  dashboardId: string
) {
  const endpoint = `${url}/user/${userId}/project/${projectId}/dashboard/${dashboardId}`;

  const response = await axiosNoAuthInstance.put(endpoint);
  console.log(response);

  return response.data as IDashboard;
}

export async function getDashboards(projectId: string) {
  const endpoint = `${url}/project/${projectId}/dashboard`;

  const response = await axiosNoAuthInstance.get(endpoint);

  console.log("response", response);

  return response.data as IListDashboard[];
}

export async function getDashboardById(projectId: string, dashboardId: string) {
  const endpoint = `${url}/project/${projectId}/dashboard/${dashboardId}`;

  const response = await axiosNoAuthInstance.get(endpoint);

  console.log("response", response);

  return response.data as IDashboard;
}
