import { DefaultLayout } from "layouts/DefaultLayout";
import { CustomLink, DashboardWrapper } from "./style";
import { Responsive, WidthProvider } from "react-grid-layout";
import { useEffect, useState } from "react";
import { IChartData, IChartItem } from "types/chat2Insight";
import _ from "lodash";
import { Button, Col, Form, Input, message, Row, Select, Tooltip } from "antd";
import dayjs from "dayjs";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import {
  CloseOutlined,
  LinkOutlined,
  LoadingOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { ItemChart } from "components/ItemChart";
import { useAuthStore } from "stores/useAuthStore";
import {
  createDashboard,
  getCharts,
  getDashboardById,
  getDashboards,
  refreshDashboard,
  updateDashboard,
} from "services/dashboard";
import { IDashboardParam } from "types/dashboard";
import { useForm } from "antd/es/form/Form";
import { Link } from "react-router-dom";

Chart.register(CategoryScale);

const ResponsiveGridLayout = WidthProvider(Responsive);

type FieldType = {
  name?: string;
  description?: string;
};

export function Dashboard() {
  const [newCols, setNewCols] = useState<number>(12);
  const [layouts, setLayout] = useState<IChartItem[]>([]);
  const { userId } = useAuthStore();
  const [listOptions, setListOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [listDashboard, setListDashboard] = useState<
    { label: string; value: string }[]
  >([
    {
      label: "Create new dashboard",
      value: "create",
    },
  ]);
  const [listCharts, setListCharts] = useState<IChartData[]>([]);
  const [chartSelected, setChartSelected] = useState<string>();
  const [dashboardId, setDashboardId] = useState<string>();
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [form] = useForm();

  const onLayoutChange = (layout: any) => {
    console.log("layout", layout);
    const temp = layouts.map((item) => {
      const data = layout.find((item2: any) => item.i === item2.i);
      return {
        ...item,
        x: data.x as number,
        y: data.y as number,
        w: data.w as number,
        h: data.h as number,
        minW: data.minW as number,
        minH: data.minH as number,
      };
    });

    setLayout(temp);
  };

  const getChartsData = async () => {
    try {
      if (!userId) return;
      const res = await getCharts(userId);
      const temp = res.map((item) => ({
        label: item?.chart_json?.title,
        value: item?.chart_id,
      }));
      setListOptions(temp);
      setListCharts(res);
    } catch (error) {
      console.log("=========", error);
    }
  };

  const getAllDashboard = async () => {
    try {
      if (!userId) return;
      const res = await getDashboards(userId);
      if (res?.length >= 0) {
        const temp = res.map((item) => ({
          label: item?.name,
          value: item?.dashboard_id,
        }));
        setListDashboard([...listDashboard, ...temp]);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getChartsData();
    getAllDashboard();
  }, []);

  const addNewItem = () => {
    try {
      if (!chartSelected) return;
      const chartData = listCharts?.filter(
        (item) => item.chart_id === chartSelected
      )?.[0];
      console.log(layouts);
      const newItem = {
        x: (layouts.length * 4) % newCols,
        y: Infinity,
        w: 4,
        h: 4,
        i: dayjs().unix().toString(),
        minW: 4,
        minH: 4,
        table_json: chartData?.table_json,
        chartjs_json: chartData?.chart_json,
        sql_result: chartData?.sql_result,
        chart_id: chartData?.chart_id,
      };

      // if (layouts.some((item) => item.x === 0 && item.y === 0)) {
      //   setLayout(
      //     layouts
      //       .map((item: any) => {
      //         if (item.x === 0) {
      //           return { y: item.y++, ...item };
      //         }
      //         return item;
      //       })
      //       .concat([newItem])
      //   );
      // } else {
      setLayout(layouts.concat([newItem]));
      // }
    } catch (error) {}
  };

  const onDrop = (layout: any, layoutItem: any, _event: any) => {
    const temp = layout.map((item: any) => {
      const data = [...layouts].find((item2: any) => item.i === item2.i);
      return {
        ...item,
        ...data,
      };
    });

    setLayout(temp);
  };

  const removeItem = (i: string) => {
    setLayout((prev) => _.reject(prev, { i: i }));
  };

  const handleBreakPointChange = (newBreakpoint: string, newCols: number) => {
    console.log(newCols);
    setNewCols(newCols);
  };

  const selectDataBase = async (value: string) => {
    try {
      setChartSelected(value);
    } catch (error) {}
  };

  const selectDashboard = async (value: string) => {
    try {
      if (value === "create") {
        setDashboardId("");
        setLayout([]);
        form.resetFields();
        return;
      }
      if (!userId) return;
      const dashboardId = value;
      const dashboard = await getDashboardById(userId, dashboardId);
      form.setFieldValue("name", dashboard.name);
      form.setFieldValue("description", dashboard.description);
      setDashboardId(dashboardId);
      setLayout(dashboard.charts || []);
    } catch (error) {}
  };

  const save = async () => {
    try {
      await form.validateFields();
      if (!userId || isRefreshing) return;
      const data: IDashboardParam = {
        name: form.getFieldValue("name"),
        description: form.getFieldValue("description"),
        project_id: userId,
        charts: layouts,
      };
      console.log(data);
      if (dashboardId) {
        const data: IDashboardParam = {
          name: form.getFieldValue("name"),
          description: form.getFieldValue("description"),
          project_id: userId,
          charts: layouts,
          dashboard_id: dashboardId,
        };
        const res = await updateDashboard(data);
      } else {
        const data: IDashboardParam = {
          name: form.getFieldValue("name"),
          description: form.getFieldValue("description"),
          project_id: userId,
          charts: layouts,
        };
        const res = await createDashboard(data);
        setDashboardId(res?.dashboard_id);
      }
      getAllDashboard();
      message.success("Save dashboard successfully!");
    } catch (error: any) {
      console.log(error);
      if (error?.errorFields) return;
      message.error("Save dashboard failed!");
    }
  };

  const refreshData = async () => {
    try {
      if (!userId || !dashboardId || isRefreshing) return;
      setIsRefreshing(true);
      const res = await refreshDashboard(userId, userId, dashboardId);
      setLayout(res.charts || []);
      message.success("Refresh dashboard successfully!");
      setIsRefreshing(false);
    } catch (error) {
      message.error("Refresh dashboard failed!");
      setIsRefreshing(false);
    }
  };

  const onFinish = (values: any) => {
    console.log("Form values:", values);
  };

  return (
    <DefaultLayout>
      <DashboardWrapper>
        <div>
          <div
            style={{
              padding: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Select
              size="middle"
              placeholder="Select dashboard"
              options={listDashboard}
              onChange={selectDashboard}
              style={{ minWidth: "380px" }}
              defaultValue={"create"}
            />
          </div>
          <div
            style={{
              padding: "10px",
              width: "100%",
            }}
          >
            <Form form={form} onFinish={onFinish} layout="vertical">
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item<FieldType>
                    name="name"
                    label="Dashboard Name"
                    rules={[
                      {
                        required: true,
                        message: "Please input dashboard name!",
                      },
                    ]}
                  >
                    <Input placeholder="Enter dashboard name" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item<FieldType>
                    name="description"
                    label="Dashboard Description"
                    rules={[
                      {
                        required: true,
                        message: "Please input dashboard description!",
                      },
                    ]}
                  >
                    <Input placeholder="Enter dashboard description" />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
          <div
            style={{
              padding: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Select
                size="middle"
                placeholder="Select chart"
                options={listOptions}
                onChange={selectDataBase}
                style={{ minWidth: "380px" }}
              />
              <Button
                onClick={addNewItem}
                type="primary"
                style={{ marginLeft: "10px" }}
              >
                <span>+</span>
                Add Chart
              </Button>
            </div>
            <div>
              {dashboardId && (
                <Tooltip title="View Dashboard">
                  <CustomLink to={`/view/${userId}/${dashboardId}`} target="_blank">
                    <LinkOutlined />
                  </CustomLink>
                </Tooltip>
              )}
              {dashboardId && (
                <Tooltip title="Refresh Dashboard">
                  <Button
                    onClick={refreshData}
                    type="dashed"
                    style={{ marginLeft: "10px" }}
                    icon={
                      isRefreshing ? <LoadingOutlined /> : <ReloadOutlined />
                    }
                  ></Button>
                </Tooltip>
              )}
              <Button
                onClick={save}
                type="primary"
                style={{ marginLeft: "10px" }}
              >
                {dashboardId ? "Save Dashboard" : "Create Dashboard"}
              </Button>
            </div>
          </div>

          <ResponsiveGridLayout
            isDraggable={true}
            isResizable={true}
            breakpoints={{
              lg: 1200,
              md: 996,
              sm: 768,
              xs: 480,
              xxs: 0,
            }}
            cols={{ lg: 12, md: 8, sm: 4, xs: 4, xxs: 4 }}
            onLayoutChange={onLayoutChange}
            onDrop={onDrop}
            style={{ minHeight: "700px" }}
            rowHeight={100}
            onBreakpointChange={handleBreakPointChange}
          >
            {layouts.map((item, index) => (
              <div data-grid={item} key={item.i}>
                <ItemChart
                  table_json={item?.table_json}
                  sql_result=""
                  chartjs_json={item.chartjs_json}
                  callBack={() => removeItem(item.i)}
                  icon={<CloseOutlined />}
                />
              </div>
            ))}
          </ResponsiveGridLayout>
        </div>
      </DashboardWrapper>
    </DefaultLayout>
  );
}
