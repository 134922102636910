import { Table } from "antd";
import Column from "antd/es/table/Column";
import { ITableData } from "types/table";
import { CustomTable } from "../style";
import { useLocation } from "react-router-dom";

export interface TableDataProps {
  data: ITableData;
}

export function TableData(props: TableDataProps) {
  const { data } = props;
  const location = useLocation();


  const columns = data.header;
  const dataSource = data.body;

  const columnElements = columns.map((item) => (
    <Column
      align="center"
      title={item.colName}
      dataIndex={item.key}
      key={item.key}
    />
  ));

  return (
    <CustomTable style={{height: location.pathname === '/dashboard' ? '100%' : 250, overflow: 'auto'}}>
      <Table
        dataSource={dataSource}
        bordered
        pagination={false}
      >
        {columnElements}
      </Table>
    </CustomTable>
  );
}
