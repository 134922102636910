import { ChartData, ChartOptions } from "chart.js";
import { ITableData } from "./table";

export enum EChartType {
  Bar = "bar",
  Line = "line",
  Scatter = "scatter",
  Doughnut = "doughnut",
}

export enum EViewType {
  Table = "table",
  Chart = "chart",
}

export interface IChartItem {
  i: string;
  x: number;
  y: number;
  w: number;
  h: number;
  minW: number;
  minH: number;
  table_json: ITableData;
  chartjs_json: IChartjs;
  sql_result: string | string[];
  image: string;
  data?: {
    title: string;
    value: number;
  };
}

export interface IChartjs {
  title: string;
  type: EChartType;
  data: ChartData<"bar" | "doughnut" | "line" | "scatter">;
  options: ChartOptions<"bar" | "doughnut" | "line" | "scatter">;
}

export interface IChartData {
  data?: {
    title: string;
    value: number;
  };
  image: string;
  table_json: ITableData;
  chart_json: IChartjs;
  chart_id: string;
  project_id: string;
  sql_result: string | string[];
}
