import { Button } from "antd";
import styled from "styled-components";

export const DefaultLayoutWrapper = styled.div`
  > .header {
    padding: 17px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      height: 32px;
    }
  }
  > .body {
    display: flex;
    background: #fafafa;
    .side-bar {
      max-width: 300px;
      width: 100%;
      padding: 24px 12px;
      background: #f5f5f5;

      height: calc(100vh - 74px);
      > div {
        margin-bottom: 8px;

        a {
          display: block;
          padding: 14px;
          color: #595959;
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          text-align: left;
          text-decoration: unset;
          border-radius: 8px;
        }
        &:hover a,
        &.active a {
          cursor: pointer;
          background: linear-gradient(252.05deg, #65ded1 0%, #3fa684 100%);
          color: #fff;
          font-weight: 600;
        }
      }
    }
    > .content {
      flex: 1;
      padding: 20px;
      overflow: auto;
      height: calc(100vh - 74px);
      & > div {
        background-color: #fff;
        border-radius: 12px;
        position: relative;
        min-height: 100%;
      }
    }
  }
`;

export const ButtonIcon = styled(Button)`
  background-color: #f0f0f0 !important;
  padding: 8px;
  width: 40px;
  height: 40px;
  border: none !important;
`;
