import { Button, Form, message, Select } from "antd";
import { AddConnectionWrapper, CustomInput, CustomInputPw } from "../style";
import {
  CheckmarkCircleFill,
  MongoLogo,
  MySQLLogo,
  PostgreLogo,
} from "assets/icons";
import { useEffect, useState } from "react";
import {
  CloseCircleOutlined,
  HomeOutlined,
  LoadingOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  createDataSource,
  deleteProjectSetting,
  getDataSourceInfo,
  testConnection,
} from "services/project";
import { useAuthStore } from "stores/useAuthStore";
import dayjs from "dayjs";

type FieldType = {
  db_type?: string;
  name?: string;
  host?: string;
  port?: string;
  username?: string;
  password?: string;
  database?: string;
};

const { Option } = Select;

export function AddConnection() {
  const [form] = Form.useForm();
  const [isTesting, setIsTesting] = useState(false);
  const [isTestingSuccessfull, setIsTestingSuccessfull] = useState(0);
  const [isSaveSuccessfull, setIsSaveSuccessfull] = useState(false);
  const { userId, setUserId, setHasSetting } = useAuthStore();

  useEffect(() => {
    getDataSource();
  }, []);

  const getDataSource = async () => {
    try {
      if (!userId) return;
      const res = await getDataSourceInfo(userId);
      form.setFieldsValue({
        db_type: res.db_type,
        name: res.name,
        host: res.host,
        port: res.port,
        username: res.username,
        database: res.database,
      });
      console.log(res);
    } catch (error) {}
  };

  useEffect(() => {
    const deleteProject = async () => {
      try {
        if (!userId) return;
        await deleteProjectSetting(userId as string);
        setHasSetting(false)
      } catch (error) {
      }
    };
    if (isSaveSuccessfull) {
      deleteProject();
    }
  }, [isSaveSuccessfull, userId]);

  const handleTestConnection = async (isTest: boolean) => {
    try {
      await form.validateFields();
      if (isTest) {
        setIsTesting(true);
        const data = {
          ...form.getFieldsValue(),
        };
        await testConnection(data);
        setIsTestingSuccessfull(1);
        setIsTesting(false);
      } else {
        setIsSaveSuccessfull(false);
        let id = userId;
        if (!id) {
          id = dayjs().unix().toString();
          setUserId(dayjs().unix().toString());
        }
        const data = {
          ...form.getFieldsValue(),
        };
        await testConnection(data);
        await createDataSource(data, id);
        setIsSaveSuccessfull(true);
      }
      message.success("Connection success");
    } catch (error) {
      if (isTest) {
        setIsTesting(false);
        setIsTestingSuccessfull(2);
      } else {
        setIsSaveSuccessfull(false);
      }
      message.error("Connection failed");
    }
  };

  return (
    <AddConnectionWrapper>
      <div className="title">Add connection</div>
      <div className="sub-title">
        Choose data source then fill all required informations to to integrate
        data into project
      </div>
      {/* <div className="db-type">
        <PostgreLogo style={{ width: "32px", height: "32px" }} /> Postgre
      </div> */}
      <Form
        form={form}
        layout="vertical"
        style={{
          width: "100%",
          display: "flex",
          gap: "10px",
          flexDirection: "column",
        }}
      >
        <Form.Item<FieldType>
          label="Database type"
          name="db_type"
          rules={[{ required: true, message: "Please select database type" }]}
        >
          <Select placeholder="Select an option">
            <Option value="PostgreSQL">
              <div style={{ display: "flex", alignItems: "center" }}>
                <PostgreLogo
                  style={{ width: "20px", height: "20px", marginRight: "10px" }}
                />{" "}
                Postgres
              </div>
            </Option>
            <Option value="Oracle Database">
              <div style={{ display: "flex", alignItems: "center" }}>
                <MongoLogo
                  style={{ width: "20px", height: "20px", marginRight: "10px" }}
                />{" "}
                Mongo
              </div>
            </Option>
            <Option value="MySQL">
              <div style={{ display: "flex", alignItems: "center" }}>
                <MySQLLogo
                  style={{ width: "20px", height: "20px", marginRight: "10px" }}
                />{" "}
                MySQL
              </div>
            </Option>
          </Select>
        </Form.Item>
        <Form.Item<FieldType>
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please input name!" }]}
        >
          <CustomInput maxLength={150} />
        </Form.Item>
        <Form.Item<FieldType>
          label="Server"
          name="host"
          rules={[{ required: true, message: "Please input host!" }]}
        >
          <CustomInput maxLength={150} />
        </Form.Item>
        <Form.Item<FieldType>
          label="Port"
          name="port"
          rules={[{ required: true, message: "Please input port!" }]}
        >
          <CustomInput maxLength={150} />
        </Form.Item>
        <Form.Item<FieldType>
          label="Database"
          name="database"
          rules={[{ required: true, message: "Please input database!" }]}
        >
          <CustomInput maxLength={150} />
        </Form.Item>
        <Form.Item<FieldType>
          label="Username"
          name="username"
          rules={[{ required: true, message: "Please input username!" }]}
        >
          <CustomInput maxLength={150} />
        </Form.Item>
        <Form.Item<FieldType>
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input password!" }]}
        >
          <CustomInputPw maxLength={150} />
        </Form.Item>
        <div className="footer">
          <div
            className="text-connection"
            onClick={() => handleTestConnection(true)}
          >
            {isTesting && <LoadingOutlined />}
            {!isTesting && isTestingSuccessfull === 1 && (
              <CheckmarkCircleFill />
            )}
            {!isTesting && isTestingSuccessfull === 2 && (
              <CloseCircleOutlined style={{ color: "red" }} />
            )}{" "}
            <span>Test connection</span>
          </div>
          <Button
            onClick={() => handleTestConnection(false)}
            type="primary"
            htmlType="submit"
          >
            Save
          </Button>
        </div>
      </Form>
    </AddConnectionWrapper>
  );
}
