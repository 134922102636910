import styled from "styled-components";

export const ViewDashboardWrap = styled.div`
  padding: 30px;
  .title {
    font-size: 24px;
    max-width: 700px;
    font-weight: bold;
    margin: auto;
    margin-bottom: 20px;
    text-align: center;
  }
  .description {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    max-width: 1200px;
    margin: auto;
    margin-bottom: 50px;
  }
`;
