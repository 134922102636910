import { IChatInfor } from "types/chatbot";
import ChatMsg from "./ChatMsg";

interface Props {
  listMsg: IChatInfor[];
  isDraggable?: boolean;
  isMarkdownContent?: boolean;
  onDragStart?: (e: any, des: string, img: string, msgId: string) => void;
}

export const ChatContent = ({ listMsg, isDraggable, onDragStart, isMarkdownContent }: Props) => {
  return (
    <>
      {listMsg.map((chat, index) => (
        <ChatMsg
          chatMsg={chat}
          index={index}
          isShowLogo={
            index === listMsg.length - 1 ||
            (listMsg?.[index + 1] && chat?.role !== listMsg?.[index + 1]?.role)
          }
          isDraggable={!!isDraggable}
          onDragStart={onDragStart}
          isMarkdownContent={isMarkdownContent}
        />
      ))}
    </>
  );
};
