import styled from "styled-components";

export const Chat2ChartWrapper = styled.div`
  > .title {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    text-align: left;
    color: #141414;
    padding: 10px 20px;
    border-bottom: 1px solid #d9d9d9;
  }

  > .body {
    display: grid;
    @media screen and (min-width: 600px) {
      grid-template-columns: 50% 50%;
    }
    @media screen and (min-width: 1500px) {
      grid-template-columns: 40% 60%;
    }
  }
  .detail-conversation {
    padding: 20px;
    height: calc(100vh - 170px);
    overflow: auto;
  }
`;
export const ChatBotContentWrap = styled.div`
  width: 100%;
  margin: auto;
`;

export const ChatBotWrap = styled.div`
  background: #fff;
  width: 460px;
  overflow: hidden;
  width: 100%;
  border-left: 1px solid #d9d9d9;
`;
