// import databases from "../../config/databases";

export const loadDatabases = async () => {
  // const databaseConfigs: DatabaseConfigs = {};

  // for (const databaseName of Object.keys(databases)) {
  //   const databaseConfig = await loadDatabaseConfig(databaseName);

  //   databaseConfigs[databaseName] = databaseConfig;
  // }

  // return databaseConfigs;
};
