import { useContext, useEffect, useRef } from "react";
import { Chat2ChartContext } from "..";
import { DataReportItem } from "./DataReportItem";

export const DetailData = () => {
  const { newContent } = useContext(Chat2ChartContext);
  const detailEndRef = useRef<null | HTMLDivElement>(null);

  const scrollIntoView = () => {
    detailEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  };

  useEffect(() => {
    setTimeout(() => {
      scrollIntoView();
    }, 1500);
  }, [newContent]);

  return (
    <div className="detail-conversation">
      <div className="title-area">
        <p className="title"></p>
      </div>
      <div>
        {newContent?.map(
          (item, index) =>
            (item?.data_quality_report_after_update ||
              item.data_quality_report_before_update) && (
              <div
                style={{
                  marginBottom: "30px",
                  border: "1px solid #F1F1F1",
                  padding: "12px",
                  borderRadius: "8px",
                }}
                key={index}
              >
                <DataReportItem
                  data={item?.data_quality_report_before_update}
                  isBefore={true}
                />
                <DataReportItem data={item?.data_quality_report_after_update} />
              </div>
            )
        )}
        <div ref={detailEndRef} />
      </div>
    </div>
  );
};
