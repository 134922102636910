import { IChartjs } from "./chat2Insight";

export interface IChatInfor {
  message_id?: number;
  id?: string;
  num_case_not_feedback?: number;
  account?: string;
  pred_date?: string;
  content: string;
  created_date?: string;
  updated_date?: string;
  is_read?: string;
  from?: string;
  message_type?: string;
  isError?: boolean;
  reaction?: REACTION;
  update_date?: string;
  chart_url?: string;
  insight?: string;
  cv_sources?: string[];
  chartjs_json?: IChartjs;
  role?: string;
}

export interface IChatBotBody {
  request_type?: string;
  message_id?: number;
  id?: string;
  account?: string;
  message?: string;
  created_date?: string;
  updated_date?: string;
  is_read?: string;
  from?: string;
  message_type?: string;
}

export interface IReadMsgRequest {
  request_type?: string;
  timestamp?: number;
}

export interface IChatData {
  status_code: number;
  surveys: IChatSurvey[];
}

export interface IChatSurvey {
  id: string;
  account: string;
  name: string;
  note: string;
  survey_message: string;
  start_date: string;
  end_date: string;
  remind_day: string;
  time: string;
  frequency: string;
  next_send_time: string;
  status: string;
  send_list: string[];
  _rid: string;
  _self: string;
  _etag: string;
  _attachments: string;
  _ts: number;
  departments?: string[];
  created_date?: string;
}

export interface IChatbotSurveyAdd {
  // [key: string]: string | string[] | File;
  // request_type: string;
  survey_message: string;
  start_date: string;
  end_date: string;
  remind_day: string;
  time: string;
  frequency: string;
  name: string;
  note: string;
  status: string;
  departments: string[];
  send_list: string;
  file?: File | undefined;
}

export interface IChatbotFeedback {
  request_type?: string;
  star_rating: string;
  rating_content: string[];
  other_experience: string;
}

export enum REACTION {
  like = "like",
  dislike = "dislike",
  none = "none",
}

export interface IReactionMsg {
  request_type: string;
  message_id: string;
  reaction: REACTION;
}

export enum submitStatus {
  create = "created",
  draft = "draft",
  update = "update",
  pause = "paused",
  running = "running",
  done = "done",
  duplicate = "duplicate",
  delete = "Delete",
}

export enum statusFilter {
  all = "All",
  create = "Created",
  draft = "Draft",
  running = "Running",
  pause = "Paused",
  done = "Done",
}

export enum repeatType {
  onetime = "Onetime",
  daily = "Daily",
  weekly = "Weekly",
  monthly = "Monthly",
  yearly = "Yearly",
}

export interface IGetChatbotSurveyParams {
  // request_type
  month?: string;
  status?: statusFilter;
  name?: string;
}

export interface IBuddyHint {
  Q: string;
  A: string;
}
