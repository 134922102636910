import { Modal } from "antd";
import { DataAnalytic } from "pages/DataAnalytic";
import { useDataSourceModalStore } from "stores/useDataSourceModalStore";

export function DataSourceModal() {
  const { isOpen, onClose } = useDataSourceModalStore();

  return (
    <>
      {isOpen && (
        <Modal onCancel={onClose} open={isOpen} width={1000} footer={null}>
          <DataAnalytic />
        </Modal>
      )}
    </>
  );
}
