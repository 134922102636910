import { Button, Checkbox, message, Select } from "antd";
import { ChooseDataSourceWrapper } from "../style";
import { useEffect, useState } from "react";
import { CustomInput } from "pages/DataConnector/style";
import {
  createProjectSetting,
  getDataSourceInfo,
  getDbSchema,
  getProjectSetting,
} from "services/project";
import { useAuthStore } from "stores/useAuthStore";
import { IDbSchema, IParamProjectSetting, ITableInfor } from "types/project";
import dayjs from "dayjs";
import { useDataSourceModalStore } from "stores/useDataSourceModalStore";
import { LoadingOutlined } from "@ant-design/icons";

export function ChooseDataSource() {
  const { onClose } = useDataSourceModalStore();
  const [tableShow, setTableShow] = useState<string>();
  const [listTableSelected, setListTableSelected] = useState<
    { table_name: string }[]
  >([]);
  const [listTables, setListTables] = useState<IDbSchema[]>([]);
  const { userId, setHasSetting } = useAuthStore();
  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    []
  );
  const [isLoadingTable, setIsLoadingTable] = useState<boolean>(false);
  const [isLoadingCol, setIsLoadingCol] = useState<boolean>(false);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [listTableUpdate, setListTableUpdate] = useState<ITableInfor[]>();
  const [listProjectSetting, setListProjectSetting] =
    useState<IParamProjectSetting>();

  const getDataSource = async () => {
    try {
      if (!userId) {
        return;
      }
      const result = await getDataSourceInfo(userId as string);
      setOptions([
        { value: result.database || "", label: result.name || "" },
      ]);
      console.log("result", result);
    } catch (error) {}
  };

  const getProject = async () => {
    try {
      if (!userId) {
        return;
      }
      const result = await getProjectSetting(userId as string);
      setListProjectSetting(result);
      console.log(listTableUpdate);
    } catch (error) {
      setHasSetting(false);
    }
  };

  useEffect(() => {
    getProject();
    getDataSource();
  }, []);

  const onChange = (item: { table_name: string }) => {
    const temp = [...listTableSelected];
    const index = temp.findIndex(
      (tempItem) => tempItem.table_name === item.table_name
    );
    if (index >= 0) {
      temp.splice(index, 1);
    } else {
      temp.push(item);
    }
    console.log("temp", temp);
    setListTableSelected(temp);
  };

  const handleShowTable = async (table_name: string) => {
    // const table = listTables.filter((table) => table.id === id) || [];
    // console.log(table);
    try {
      setTableShow(table_name);
      if (listTableUpdate && listTableUpdate?.length > 0) {
        if (listTableUpdate.some((item) => item.table_name === table_name)) {
          return;
        }
      }

      setIsLoadingCol(true);
      const temp = listTableUpdate ? [...listTableUpdate] : [];
      const result = await getDbSchema(userId as string, table_name);
      const listColumn = result[0].columns.map((item) => {
        return {
          column_name: item.column_name,
          description: "",
        };
      });
      const data = {
        table_name: table_name,
        description: "",
        columns: listColumn,
      };
      temp.push(data);
      setListTableUpdate(temp);
      console.log("data", temp);
      setIsLoadingCol(false);
    } catch (error) {
      setIsLoadingCol(false);
    }
  };

  const selectDataBase = async (value: string) => {
    try {
      setIsLoadingTable(true);
      const result = await getDbSchema(userId as string);
      const temp = listProjectSetting?.tables?.map((item) => {
        return {
          table_name: item.table_name,
        };
      });
      if (listProjectSetting?.tables?.length) {
        setListTableUpdate(listProjectSetting?.tables);
      }
      console.log("temp", temp);
      temp && setListTableSelected(temp);
      setListTables(result);
      setIsLoadingTable(false);
    } catch (error) {
      setIsLoadingTable(false);
    }
  };

  const handleChangeCol = (
    column_name: string,
    event: React.ChangeEvent<HTMLInputElement>,
    isTableDes: boolean
  ) => {
    const temp = listTableUpdate ? [...listTableUpdate] : [];
    const index = temp.findIndex((item) => item.table_name === tableShow);
    if (index !== -1) {
      const columnIndex = temp[index].columns.findIndex(
        (col) => col.column_name === column_name
      );
      if (isTableDes) {
        temp[index].description = event.target.value;
      } else {
        if (columnIndex !== -1) {
          temp[index].columns[columnIndex].description = event.target.value;
        }
      }
      setListTableUpdate(temp);
    }
    console.log(temp);
  };

  const handleSaveSetting = async () => {
    try {
      if (!listTableSelected?.length || !listTableUpdate?.length) return;
      setIsSubmit(true);
      const temp = listTableUpdate.filter((item) =>
        listTableSelected.some((table) => table.table_name === item.table_name)
      );
      const data = {
        name: dayjs().unix().toString(),
        tables: temp,
      };
      await createProjectSetting(data, userId || "");
      setHasSetting(true);
      setIsSubmit(false);
      message.success("Save data source setting successfully!");
      onClose();
    } catch (error) {
      setIsSubmit(false);
      message.error("Save data source setting failed!");
    }
  };

  return (
    <ChooseDataSourceWrapper>
      <div className="title">Hi!</div>
      <div className="sub-title">
        Please choose your data source & table to start report generation
      </div>
      <div className="select-data-area">
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "16px",
              gap: "10px",
            }}
          >
            <p className="label">Data Source:</p>
            <Select
              size="large"
              placeholder="Select data source"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={options}
              onChange={selectDataBase}
            />
          </div>
          <div className="select-table">
            {isLoadingTable && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <LoadingOutlined />
              </div>
            )}
            {listTables.map((item, index) => {
              return (
                <div
                  className={`table-item ${
                    item.table_name === tableShow ? "active" : ''
                  }`}
                  key={(item.table_name || "") + index}
                  onClick={() => handleShowTable(item.table_name as string)}
                >
                  <span style={{paddingRight: '10px'}}>{item.table_name}</span>
                  <Checkbox
                    defaultChecked={listTableSelected.some(
                      (selectedTable) =>
                        selectedTable.table_name === item.table_name
                    )}
                    onChange={() =>
                      onChange({ table_name: item.table_name || "" })
                    }
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div className="select-properties">
          {isLoadingCol ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <LoadingOutlined />
            </div>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "16px",
                  gap: "10px",
                }}
              >
                <p className="label">{tableShow || "table_name"}:</p>
                <CustomInput
                  maxLength={500}
                  style={{ flex: 1 }}
                  placeholder="Description"
                  onChange={(e) => handleChangeCol("", e, true)}
                  value={
                    tableShow && listTableUpdate
                      ? listTableUpdate?.filter(
                          (item) => item.table_name === tableShow
                        )?.[0]?.description
                      : ""
                  }
                />
              </div>
              <div
                className="property-header"
                style={{ fontWeight: 700, background: "linear-gradient(90deg, #E9FAF6 53.02%, #E4FFFB 100%)" }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  Field Name
                </div>
                <div>Description</div>
              </div>
              {tableShow &&
                listTableUpdate &&
                listTableUpdate
                  ?.filter((item) => item.table_name === tableShow)?.[0]
                  ?.columns?.map((item) => (
                    <div key={item.column_name} className="property-item">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {item.column_name}
                      </div>
                      <div>
                        <CustomInput
                          maxLength={500}
                          style={{ flex: 1 }}
                          value={item.description}
                          onChange={(e) =>
                            handleChangeCol(item.column_name, e, false)
                          }
                        />
                      </div>
                    </div>
                  ))}
            </>
          )}
        </div>
      </div>
      <div className="footer">
        <Button
          type="primary"
          htmlType="submit"
          onClick={handleSaveSetting}
          disabled={isSubmit}
        >
          Submit
        </Button>
      </div>
    </ChooseDataSourceWrapper>
  );
}
