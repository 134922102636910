import { DefaultLayout } from "layouts/DefaultLayout";
import { AddConnection } from "./components/AddConnection";
import { DataConnectorWrapper } from "./style";

export function DataConnector() {
  return (
    <DefaultLayout>
      <DataConnectorWrapper>
        <AddConnection />
      </DataConnectorWrapper>
    </DefaultLayout>
  );
}
