import { createContext, useEffect, useState } from "react";
import { Chat2ChartWrapper } from "./style";
import { ChatbotWindow } from "./components/ChatbotWindow";
import { DetailData } from "./components/DetailData";
import { DefaultLayout } from "layouts/DefaultLayout";
import { IChartjs } from "types/chat2Insight";
import { useAuthStore } from "stores/useAuthStore";
import { useDataSourceModalStore } from "stores/useDataSourceModalStore";
import { ITableData } from "types/table";

export interface INewContent {
  sql_result: string | string[];
  chartjs_json: IChartjs;
  table_json?: ITableData;
}
interface Chat2ChartContextValue {
  newContent?: INewContent[];
  updateNewContent: (newContent: INewContent[]) => void;
}

export const Chat2ChartContext = createContext<Chat2ChartContextValue>({
  newContent: undefined,
  updateNewContent: () => {},
});

export function Chat2Chart() {
  const [newContent, setNewContent] = useState<INewContent[]>();
  const { hasSetting } = useAuthStore();
  const { onOpen } = useDataSourceModalStore();

  useEffect(() => {
    if (!hasSetting) {
      onOpen();
    }
  }, [hasSetting, onOpen]);

  return (
    <DefaultLayout>
      <Chat2ChartContext.Provider
        value={{
          newContent,
          updateNewContent: (newContent) => setNewContent(newContent),
        }}
      >
        <Chat2ChartWrapper>
          <div className="title">Chat2Insight</div>
          <div className="body">
            <DetailData />
            <ChatbotWindow />
          </div>
        </Chat2ChartWrapper>
      </Chat2ChartContext.Provider>
    </DefaultLayout>
  );
}
