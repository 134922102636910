export { ReactComponent as CheckmarkCircleFill } from "./checkmark-circle-fill.svg";
export { ReactComponent as PostgreLogo } from "./postgre-sql-logo.svg";
export { ReactComponent as SendIcon } from "./send-icon.svg";
export { ReactComponent as MongoLogo } from "./mongo-logo.svg";
export { ReactComponent as MySQLLogo } from "./mysql-logo.svg";




















