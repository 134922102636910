import { Image } from "antd";
import { ButtonIcon, DefaultLayoutWrapper } from "./style";
import headerLogo from "assets/images/header-logo.png";
import { Link, useLocation } from "react-router-dom";
import { DataSourceModal } from "components/DataSourceModal";
import { SettingOutlined } from "@ant-design/icons";
import { useDataSourceModalStore } from "stores/useDataSourceModalStore";

type Props = {
  children: React.ReactNode;
};

export function DefaultLayout({ children }: Props) {
  const { onOpen } = useDataSourceModalStore();
  const route = useLocation();

  return (
    <DefaultLayoutWrapper>
      <div className="header">
        <div>
          <Image src={headerLogo} alt="" preview={false}/>
        </div>
        <div>
          <ButtonIcon onClick={onOpen}>
            <SettingOutlined style={{fontSize: '24px'}}/>
          </ButtonIcon>
        </div>
      </div>
      <div className="body">
        <div className="side-bar">
          <div className={route.pathname === '/data-connector' ? 'active' : ''}>
            <Link to="/data-connector">Data Connector</Link>
          </div>
          <div className={route.pathname === '/data-quality' ? 'active' : ''}>
            <Link to="/data-quality">Data Quality</Link>
          </div>
          <div className={route.pathname === '/chat-2-insight' ? 'active' : ''}>
            <Link to="/chat-2-insight">Chat2Insight</Link>
          </div>
          <div className={route.pathname === '/data-modeling' ? 'active' : ''}>
            <Link to="/data-modeling">Data Modeling</Link>
          </div>
          <div className={route.pathname === '/dashboard' ? 'active' : ''}>
            <Link to="/dashboard">Dashboard</Link>
          </div>
        </div>
        <div className="content">
          <div>{children}</div>
        </div>
      </div>
      <DataSourceModal />
    </DefaultLayoutWrapper>
  );
}
