import { Link } from "react-router-dom";
import styled from "styled-components";

export const DashboardWrapper = styled.div`
  .react-grid-layout {
    position: relative;
    transition: height 200ms ease;
  }
  .react-grid-item.cssTransforms {
    transition-property: transform;
    z-index: 3;
  }
  .react-grid-item.resizing {
    z-index: 1;
    will-change: width, height;
  }

  .react-grid-item.react-draggable-dragging {
    transition: none;
    z-index: 3;
    will-change: transform;
  }

  .react-grid-item.react-grid-placeholder {
    background: #cdf7f0;
    border-radius: 16px;
    opacity: 0.2;
    transition-duration: 100ms;
    z-index: 2;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    pointer-events: none;
  }

  .hide-resize-icon .react-grid-item > .react-resizable-handle {
    display: none;
  }

  .react-grid-item > .react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 5px;
    right: 5px;
    cursor: se-resize;
  }

  .react-grid-item > .react-resizable-handle::after {
    content: "";
    position: absolute;
    right: 3px;
    bottom: 3px;
    width: 5px;
    height: 5px;
    border-right: 2px solid rgba(0, 0, 0, 0.4);
    border-bottom: 2px solid rgba(0, 0, 0, 0.4);
  }
`;

export const CustomLink = styled(Link)`
  border-style: dashed;
  border-color: #d9d9d9;
  background: #ffffff;
  font-size: 14px;
  line-height: 1.5714285714285714;
  height: 32px;
  border-radius: 6px;
  border-width: 1px;
  width: 32px;
  display: inline-flex;
  color: #3fa684;
  justify-content: center;
`;
