import chatbotLogo from "assets/images/chatbot-logo.png";
import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import { Form, Input, message } from "antd";
import dayjs from "dayjs";
import { ChatContent } from "components/WindowChat/components/ChatContent";
import { useAuthStore } from "stores/useAuthStore";
import { IChat2ChartInfor } from "types/chat2Chart";
import { ChatBotContentWrap, ChatBotWrap } from "../style";
import {
  ChatBotBody,
  ChatBotMessageReceive,
  TypingMsg,
  ChatBotFooter,
} from "pages/Chat2Insight/styled";
import { Chat2ChartContext, IDataQualityReportNewChat } from "..";
import { SendIcon } from "assets/icons";
import { IDataQualityParam } from "types/dataQuality";
import { sendMsg } from "services/dataQuality";
import AutoResizeTextarea from "components/AutoResizeTextarea";

export const ChatbotWindow = () => {
  const [form] = Form.useForm();
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const [isLoadingMsg, setIsLoadingMsg] = useState(false);
  const listChatRef = useRef<IChat2ChartInfor[]>([]);
  const hasNewMsg = useRef<boolean>(false);
  const [listChat, setListChat] = useState<IChat2ChartInfor[]>([
    {
      content: `**Ivy Analytics** is a versatile **platform** designed to enhance productivity across various industries. At its core, **Ivy Analytics** utilizes advanced AI technology, leveraging **MLOps and LLM** to efficiently clean and extract data from diverse sources, build insightful no-code dashboards, recommendation, prediction and forecasting and accurate AI models. Additionally, it functions as a specialized domain LLM agent **with conversational natural language interface**, capable of fetching data from multiple domains, understanding specific domain jargon and concepts, and transforming raw data into actionable insights.`,
    },
  ]);
  const userLogin = "human";
  const { userId } = useAuthStore();
  const [lastSessionId, setLastSessionId] = useState<string>();
  const { updateNewContent, table, dbInfor } = useContext(Chat2ChartContext);
  const [textAreaHeight, setTextAreaHeight] = useState(78);
  const textAreaRef = useRef<HTMLDivElement | null>(null);
  const [text, setText] = useState("");

  const scrollIntoView = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  };

  useEffect(() => {
    setListChat([
      {
        content: `**Ivy Analytics** is a versatile **platform** designed to enhance productivity across various industries. At its core, **Ivy Analytics** utilizes advanced AI technology, leveraging **MLOps and LLM** to efficiently clean and extract data from diverse sources, build insightful no-code dashboards, recommendation, prediction and forecasting and accurate AI models. Additionally, it functions as a specialized domain LLM agent **with conversational natural language interface**, capable of fetching data from multiple domains, understanding specific domain jargon and concepts, and transforming raw data into actionable insights.`,
      },
    ]);
    setLastSessionId(undefined);
  }, [table]);

  useEffect(() => {
    listChatRef.current = [...listChat];
  }, [listChat]);

  const onFinish = (values: any) => {
    if (!dbInfor || !table) {
      message.error("Please select table!");
      return;
    }
    if (!values?.msgContent || isLoadingMsg) return;
    setListChat((pre) => [
      ...pre,
      {
        role: userLogin || "",
        content: values.msgContent,
        is_read: "no",
      },
    ]);
    form.setFieldValue("msgContent", "");
    scrollIntoView();
    handleSendMsg(values.msgContent);
  };

  const handleSendMsg = async (msg: string) => {
    setIsLoadingMsg(true);
    if (!dbInfor || !table) return;
    try {
      const data: IDataQualityParam = {
        username: userId || "",
        session_id: lastSessionId || dayjs().unix().toString(),
        user_query: msg,
        db_table_name: table,
        database_type: "PostgreSQL",
        credentials: {
          ...dbInfor,
          port: dbInfor?.port.toString(),
          server: dbInfor?.host,
        },
      };
      scrollIntoView();
      const res = await sendMsg(data);
      console.log(res);
      if (res) {
        hasNewMsg.current = true;
        const data: IChat2ChartInfor = {
          content: res.chat_response || "",
          role: "cv-matchmaker-bot",
          id: dayjs().unix().toString(),
        };
        // handleIsReadMsg(res[res.length - 1].id);
        setListChat((pre) => [...pre, data]);
        setLastSessionId(res?.session_id);
        const dataQualityReportNewChat: IDataQualityReportNewChat = {
          data_quality_report_after_update:
            res?.data_quality_report_after_update,
          data_quality_report_before_update:
            res?.data_quality_report_before_update,
        };
        const temp: IDataQualityReportNewChat[] = [dataQualityReportNewChat];
        updateNewContent(temp);
      }
      setIsLoadingMsg(false);
      scrollIntoView();
    } catch (error) {
      setIsLoadingMsg(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      scrollIntoView();
    }, 300);
  }, [listChat]);

  const divStyle = {
    height: `calc(100vh - ${textAreaHeight}px - 167px)`,
  };

  useEffect(() => {
    setTextAreaHeight((textAreaRef.current as HTMLDivElement)?.scrollHeight);
  }, [text]);

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value);
  };

  return (
    <ChatBotWrap>
      {/* <ChatBotHeader>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ marginRight: "12px" }}>
            <img
              src={chatbotLogo}
              alt="chatbot logo"
              width={"40px"}
              height={"40px"}
            />
          </div>
        </div>
      </ChatBotHeader> */}
      <ChatBotBody
        // onClick={() => handleIsReadMsg()}
        // onScroll={handleScroll}
        style={divStyle}
      >
        <ChatBotContentWrap>
          {/* <ChatContent listMsg={listOldChat} isMarkdownContent={true} /> */}
          <ChatContent listMsg={listChat} isMarkdownContent={true} />
          {isLoadingMsg && (
            <ChatBotMessageReceive>
              <div style={{ width: "32px" }}>
                <img
                  src={chatbotLogo}
                  alt="chatbot logo"
                  width={"32px"}
                  height={"32px"}
                />
              </div>
              <div className="content">
                <TypingMsg>
                  <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </TypingMsg>
              </div>
            </ChatBotMessageReceive>
          )}
          <div ref={messagesEndRef} />
        </ChatBotContentWrap>
      </ChatBotBody>
      <ChatBotFooter ref={textAreaRef}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            background: "#F5F5F5",
            padding: "11px 24px",
            borderRadius: "100px",
            boxShadow: "0px 1px 4px 0px #00000014",
            border: "1px solid #C4EFE4",
          }}
        >
          <Form
            form={form}
            onFinish={onFinish}
            style={{ display: "flex", width: "100%" }}
          >
            <Form.Item name="msgContent" style={{ flex: 1 }}>
              <AutoResizeTextarea
                callBack={() => form.submit()}
                value={text}
                onChange={handleChange}
              />
            </Form.Item>
            <Form.Item>
              <button type="submit" style={{ display: "flex", cursor: 'pointer' }}>
                <SendIcon />
              </button>
            </Form.Item>
          </Form>
        </div>
      </ChatBotFooter>
    </ChatBotWrap>
  );
};
