import "./App.css";
import { ErrorBoundary } from "react-error-boundary";
import { NotFoundPage } from "pages/NotFoundPage";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { DataConnector } from "pages/DataConnector";
import { DataAnalytic } from "pages/DataAnalytic";
import { Chat2Chart } from "pages/Chat2Chart";
import { Dashboard } from "pages/Dashboard";
import { DataQuality } from "pages/DataQuality";
import Visualizer from "pages/Visualizer";
import { ViewDashboard } from "pages/ViewDashboard";

function App() {
  return (
    <BrowserRouter>
      <ErrorBoundary fallbackRender={NotFoundPage}>
        <Routes>
          <Route path="/" element={<Navigate to="/data-connector" replace />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/data-connector" element={<DataConnector />} />
          <Route path="/data-analytics" element={<DataAnalytic />} />
          <Route path="/data-quality" element={<DataQuality />} />
          <Route path="/chat-2-insight" element={<Chat2Chart />} />
          <Route path="/data-modeling" element={<Visualizer />} />
          <Route path="/view/:userId/:id" element={<ViewDashboard />} />
          <Route path="*" element={<Navigate replace to="/404" />} />
        </Routes>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

export default App;
