import { Input } from "antd";
import styled from "styled-components";

export const DataConnectorWrapper = styled.div`
  padding: 20px;
  height: calc(100vh - 114px);
`;

export const CustomInput = styled(Input)`
  height: 34px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #bfbfbf;
  margin-bottom: 0;
`;

export const CustomInputPw = styled(Input.Password)`
  height: 34px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #bfbfbf;
`;

export const AddConnectionWrapper = styled.div`
  width: 575px;
  margin: auto;
  .title {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
  }
  .sub-title {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    margin-bottom: 30px;
  }
  .db-type {
    padding: 12px;
    background: linear-gradient(90deg, #e9faf6 53.02%, #e4fffb 100%);
    border-radius: 8px;
    background: linear-gradient(90deg, #e9faf6 53.02%, #e4fffb 100%);
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    margin: 16px 0;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .text-connection {
    display: flex;
    align-items: center;
    gap: 7px;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: #3fa684;
    cursor: pointer;
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
