import React, { useRef, useEffect, ChangeEvent, KeyboardEvent } from "react";

interface AutoResizeTextareaProps {
  value: string;
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  callBack?: () => void;
}

const AutoResizeTextarea: React.FC<AutoResizeTextareaProps> = ({
  value,
  onChange,
  callBack,
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "24px";
      if (value) {
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      }
    }
  }, [value]);

  const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      callBack && callBack();
    }
  };

  return (
    <div style={{overflow: 'auto', maxHeight: '90px'}}>
      <textarea
        ref={textareaRef}
        value={value}
        onChange={onChange}
        onKeyDown={handleKeyDown}
        style={{
          outline: "none",
          width: "100%",
          overflow: "hidden",
          resize: "none",
          background: "rgb(245, 245, 245)",
          border: "none",
        }}
      />
    </div>
  );
};

export default AutoResizeTextarea;
