// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Flow {
  width: 100%;
  height: 100%;

  flex-grow: 1;
  font-size: 12px;
}

:not(:root):fullscreen::backdrop {
  background-color: #FFF;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Visualizer/style/flow.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;;EAEZ,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".Flow {\r\n  width: 100%;\r\n  height: 100%;\r\n\r\n  flex-grow: 1;\r\n  font-size: 12px;\r\n}\r\n\r\n:not(:root):fullscreen::backdrop {\r\n  background-color: #FFF;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
