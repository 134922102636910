import chatbotLogo from "assets/images/chatbot-logo.png";
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Form } from "antd";
import { IBuddyHint } from "types/chatbot";
import dayjs from "dayjs";
import { ChatContent } from "components/WindowChat/components/ChatContent";
import { useAuthStore } from "stores/useAuthStore";
import { getChatHistory, sendMsg } from "services/chat2chart";
import { IChat2ChartInfor, IChat2ChartParam } from "types/chat2Chart";
import { ChatBotContentWrap, ChatBotWrap } from "../style";
import {
  ChatBotBody,
  ChatBotMessageReceive,
  TypingMsg,
  ChatBotFooter,
} from "pages/Chat2Insight/styled";
import { Chat2ChartContext, INewContent } from "..";
import { SendIcon } from "assets/icons";
import AutoResizeTextarea from "components/AutoResizeTextarea";

export const ChatbotWindow = () => {
  const [form] = Form.useForm();
  const [isOpenSuggestion, setIsOpenSuggestion] = useState<boolean>(true);
  const [selectedSuggestion, setSelectedSuggestion] = useState<string>("");
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMsg, setIsLoadingMsg] = useState(false);
  const [listOldChat, setListOldChat] = useState<IChat2ChartInfor[]>([
    {
      content: `**Ivy Analytics** is a versatile **platform** designed to enhance productivity across various industries. At its core, **Ivy Analytics** utilizes advanced AI technology, leveraging **MLOps and LLM** to efficiently clean and extract data from diverse sources, build insightful no-code dashboards, recommendation, prediction and forecasting and accurate AI models. Additionally, it functions as a specialized domain LLM agent **with conversational natural language interface**, capable of fetching data from multiple domains, understanding specific domain jargon and concepts, and transforming raw data into actionable insights.`,
    },
  ]);
  const listOldChatRef = useRef<IChat2ChartInfor[]>([]);
  const listChatRef = useRef<IChat2ChartInfor[]>([]);
  const hasNewMsg = useRef<boolean>(false);
  const [suggestionHeight, setSuggestionHeight] = useState(0);
  const [listChat, setListChat] = useState<IChat2ChartInfor[]>([]);
  const [listHints, setListHints] = useState<IBuddyHint[]>([]);
  let timer: any;
  const isLoadingCheckIsRead = useRef<boolean>(false);
  const userLogin = "human";
  const { userId, hasSetting } = useAuthStore();
  const suggestionRef = useRef<HTMLDivElement | null>(null);
  const [lastSessionId, setLastSessionId] = useState<string>();
  const { newContent, updateNewContent } = useContext(Chat2ChartContext);
  const [textAreaHeight, setTextAreaHeight] = useState(78);
  const textAreaRef = useRef<HTMLDivElement | null>(null);

  const [text, setText] = useState("");

  useEffect(() => {
    setTimeout(() => {
      scrollIntoView();
    }, 0);
  }, [listOldChat]);

  const scrollIntoView = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  };

  // const handleScroll = () => {
  //   clearTimeout(timer);
  //   timer = setTimeout(() => {
  //     handleIsReadMsg();
  //   }, 500);
  // };

  useEffect(() => {
    listChatRef.current = [...listChat];
  }, [listChat]);

  const getListMessage = async () => {
    try {
      if (!hasSetting || !userId) return;
      setIsLoading(true);
      let data = await getChatHistory(userId); /* list_message, new_message */
      if (
        data?.length === 0 ||
        listOldChat?.some((item) => item.id === data?.[0]?.id)
      )
        return;
      const temp: INewContent[] = [];
      data.forEach((item) => {
        if (item.chartjs_json) {
          temp.push({
            table_json: item?.table_json,
            chartjs_json: item.chartjs_json,
            sql_result: item.sql_result || "",
          });
        }
      });
      updateNewContent(temp);
      setListOldChat((pre) => [...pre, ...data]);
      listOldChatRef.current = data;
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getListMessage();
  }, [hasSetting]);

  // const handleSelectSuggestion = (hint: string) => {
  //   setSelectedSuggestion(hint);
  //   setListChat((pre) => [
  //     ...pre,
  //     {
  //       from: userLogin || "",
  //       content: hint,
  //     },
  //   ]);
  //   scrollIntoView();
  //   setIsLoadingMsg(true);
  //   setTimeout(() => {
  //     setIsLoadingMsg(false);
  //     setListChat((pre) => [
  //       ...pre,
  //       {
  //         from: "happi-bot",
  //         content: listHints.find((item) => item.Q === hint)?.A || "",
  //       },
  //     ]);
  //   }, 1000);
  //   setIsOpenSuggestion(false);
  // };

  // const openSuggestion = () => {
  //   setSelectedSuggestion("");
  //   setIsOpenSuggestion((prev) => !prev);
  // };

  // useEffect(() => {
  //   console.log((suggestionRef.current as HTMLDivElement)?.clientHeight);
  //   setSuggestionHeight(
  //     (suggestionRef.current as HTMLDivElement)?.clientHeight - 11 || 0
  //   );
  // }, [isOpenSuggestion]);

  const onFinish = (values: any) => {
    if (!values?.msgContent || isLoadingMsg) return;
    setListChat((pre) => [
      ...pre,
      {
        role: userLogin || "",
        content: values.msgContent,
        is_read: "no",
      },
    ]);
    form.setFieldValue("msgContent", "");
    scrollIntoView();
    handleSendMsg(values.msgContent);
  };

  const handleSendMsg = async (msg: string) => {
    setIsLoadingMsg(true);
    try {
      const data: IChat2ChartParam = {
        user_id: userId || "",
        session_id: lastSessionId || dayjs().unix().toString(),
        query: msg,
      };
      scrollIntoView();
      const res = await sendMsg(data);
      console.log(res);
      if (res.length > 0) {
        hasNewMsg.current = true;
        // handleIsReadMsg(res[res.length - 1].id);
        setListChat((pre) => [...pre, ...res]);
        setLastSessionId(res?.[0]?.session_id);
        if (res?.[0].chartjs_json) {
          const item = {
            table_json: res?.[0]?.table_json,
            chartjs_json: res?.[0].chartjs_json,
            sql_result: res?.[0].sql_result || "",
          };
          const temp: INewContent[] = newContent
            ? [...newContent, item]
            : [item];
          updateNewContent(temp);
        }
      }
      setIsLoadingMsg(false);
      scrollIntoView();
    } catch (error) {
      console.log(error);
      // if (listChat.length > 0) {
      //   setListChat((pre) => {
      //     pre[pre.length - 1].isError = true;
      //     return pre;
      //   });
      // }
      setIsLoadingMsg(false);
    }
  };

  // const handleIsReadMsg = async (id?: string) => {
  //   if (!hasNewMsg.current || isLoadingCheckIsRead.current) return;
  //   try {
  //     isLoadingCheckIsRead.current = true;
  //     let tempId = id;
  //     if (!id) {
  //       if (listOldChat.length > 0) {
  //         tempId =
  //           listOldChat[listOldChat.length - 1].is_read === "no"
  //             ? listOldChat[listOldChat.length - 1].id
  //             : undefined;
  //       }
  //       if (listChat.length > 1) {
  //         tempId =
  //           listChat[listChat.length - 1].is_read === "no"
  //             ? listChat[listChat.length - 1].id
  //             : undefined;
  //       }
  //     }
  //     if (!tempId) return;
  //     const data: IReadMsgRequest = {
  //       request_type: "happi_latest_message_read",
  //       timestamp: dayjs().unix(),
  //     };
  //     isLoadingCheckIsRead.current = false;
  //   } catch (error) {
  //     console.log(error);
  //     isLoadingCheckIsRead.current = false;
  //   }
  // };

  useEffect(() => {
    setTimeout(() => {
      scrollIntoView();
    }, 300);
  }, [listChat]);

  const divStyle = {
    height: `calc(100vh - ${textAreaHeight}px - 163px)`,
  };

  useEffect(() => {
    console.log("aaa", (textAreaRef.current as HTMLDivElement)?.scrollHeight);
    setTextAreaHeight((textAreaRef.current as HTMLDivElement)?.scrollHeight);
  }, [text]);

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value);
  };

  return (
    <ChatBotWrap>
      {/* <ChatBotHeader>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ marginRight: "12px" }}>
            <img
              src={chatbotLogo}
              alt="chatbot logo"
              width={"40px"}
              height={"40px"}
            />
          </div>
        </div>
      </ChatBotHeader> */}
      <ChatBotBody
        // onClick={() => handleIsReadMsg()}
        // onScroll={handleScroll}
        style={divStyle}
      >
        <ChatBotContentWrap>
          <ChatContent listMsg={listOldChat} isMarkdownContent={true} />
          <ChatContent listMsg={listChat} isMarkdownContent={true} />
          {isLoadingMsg && (
            <ChatBotMessageReceive>
              <div style={{ width: "32px" }}>
                <img
                  src={chatbotLogo}
                  alt="chatbot logo"
                  width={"32px"}
                  height={"32px"}
                />
              </div>
              <div className="content">
                <TypingMsg>
                  <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </TypingMsg>
              </div>
            </ChatBotMessageReceive>
          )}
          <div ref={messagesEndRef} />
        </ChatBotContentWrap>
      </ChatBotBody>
      <ChatBotFooter ref={textAreaRef}>
        {/* {isOpenSuggestion && (
          <div className="message-options" ref={suggestionRef}>
            {listHints.map((item) => (
              <div
                key={item.Q}
                className={`message ${selectedSuggestion === item.Q && "active"}`}
                onClick={() => handleSelectSuggestion(item.Q)}
              >
                {item.Q}
              </div>
            ))}
          </div>
        )} */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            background: "#F5F5F5",
            padding: "11px 24px",
            borderRadius: "100px",
            boxShadow: "0px 1px 4px 0px #00000014",
            border: "1px solid #C4EFE4",
          }}
        >
          <Form
            form={form}
            onFinish={onFinish}
            style={{ display: "flex", width: "100%" }}
          >
            <Form.Item name="msgContent" style={{ flex: 1 }}>
              {/* <Input
                placeholder="Type Message..."
                style={{
                  background: "#F5F5F5",
                }}
              /> */}
              <AutoResizeTextarea
                callBack={() => form.submit()}
                value={text}
                onChange={handleChange}
              />
            </Form.Item>
            <Form.Item>
              <button type="submit" style={{ display: "flex", cursor: 'pointer' }}>
                <SendIcon />
              </button>
            </Form.Item>
          </Form>
        </div>
      </ChatBotFooter>
    </ChatBotWrap>
  );
};
