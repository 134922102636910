import { IChat2ChartInfor, IChat2ChartParam } from "types/chat2Chart";
import { axiosNoAuthInstance } from "utils/axios";

// const url = "https://analytic-service.happyforest-0c743beb.southeastasia.azurecontainerapps.io"

const url = process.env.REACT_APP_API_ANALYTIC;

export async function sendMsg(item: IChat2ChartParam) {
  const endpoint = `${url}/analytic`;

  const response = await axiosNoAuthInstance.post(endpoint, item);
  const data = response?.data?.data || [];

  return data as IChat2ChartInfor[];
}

export async function getChatHistory(userId: string): Promise<IChat2ChartInfor[]> {
  const endpoint = `${url}/conversation/${userId}`;

  const response = await axiosNoAuthInstance.get(endpoint);

  const data = response?.data?.conversations || [];

  return data as IChat2ChartInfor[];
}