// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info-popup {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100000;
  background-color: rgba(0, 0, 0, 0.7);
}
.info-popup__inner {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  max-height: 100%;
  z-index: 200000;
  padding: 16px 16px 8px;
  transform: translateX(-50%) translateY(-50%);
  overflow-y: auto;
  border-radius: 8px;
  background-color: #FFF;
  font-size: 16px;
  line-height: 1.4;
}
@media (min-width: 512px) {
  .info-popup__inner {
    max-width: 576px;
  }
}
.info-popup__headline {
  margin-top: 0;
}
.info-popup__database-name {
  margin-bottom: 8px;
}
.info-popup__database-name + p {
  margin-top: 0;
}
.info-popup__close-icon {
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
}
.info-popup__close-icon:hover {
  opacity: 0.7;
}`, "",{"version":3,"sources":["webpack://./src/pages/Visualizer/style/info-popup.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,oCAAA;AACF;AACE;EACE,eAAA;EACA,QAAA;EACA,SAAA;EACA,WAAA;EACA,gBAAA;EACA,eAAA;EACA,sBAAA;EACA,4CAAA;EACA,gBAAA;EAEA,kBAAA;EAEA,sBAAA;EAEA,eAAA;EACA,gBAAA;AAFJ;AAII;EAlBF;IAmBI,gBAAA;EADJ;AACF;AAIE;EACE,aAAA;AAFJ;AAKE;EACE,kBAAA;AAHJ;AAME;EACE,aAAA;AAJJ;AAOE;EACE,kBAAA;EACA,SAAA;EACA,WAAA;EAEA,eAAA;AANJ;AAUI;EACE,YAAA;AARN","sourcesContent":[".info-popup {\r\n  width: 100%;\r\n  height: 100%;\r\n  position: fixed;\r\n  top: 0;\r\n  left: 0;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  z-index: 100000;\r\n  background-color: rgba(0, 0, 0, 0.7);\r\n\r\n  &__inner {\r\n    position: fixed;\r\n    top: 50%;\r\n    left: 50%;\r\n    width: 100%;\r\n    max-height: 100%;\r\n    z-index: 200000;\r\n    padding: 16px 16px 8px;\r\n    transform: translateX(-50%) translateY(-50%);\r\n    overflow-y: auto;\r\n\r\n    border-radius: 8px;\r\n\r\n    background-color: #FFF;\r\n\r\n    font-size: 16px;\r\n    line-height: 1.4;\r\n\r\n    @media (min-width: 512px) {\r\n      max-width: 576px;\r\n    }\r\n  }\r\n\r\n  &__headline {\r\n    margin-top: 0;\r\n  }\r\n\r\n  &__database-name {\r\n    margin-bottom: 8px;\r\n  }\r\n\r\n  &__database-name + p {\r\n    margin-top: 0;\r\n  }\r\n\r\n  &__close-icon {\r\n    position: absolute;\r\n    top: 12px;\r\n    right: 12px;\r\n\r\n    cursor: pointer;\r\n  }\r\n\r\n  &__close-icon {\r\n    &:hover {\r\n      opacity: 0.7;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
