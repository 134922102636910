import { useContext, useEffect, useRef, useState } from "react";
import { Chat2ChartContext } from "..";
import { ItemChart } from "components/ItemChart";
import { EChartType, IChartData, IChartjs } from "types/chat2Insight";
import { SaveOutlined } from "@ant-design/icons";
import { getCharts, saveChart } from "services/dashboard";
import { useAuthStore } from "stores/useAuthStore";
import { message, Tooltip } from "antd";
import { ITableData } from "types/table";

export const DetailData = () => {
  const { newContent } = useContext(Chat2ChartContext);
  const detailEndRef = useRef<null | HTMLDivElement>(null);
  const { userId } = useAuthStore();
  const [listCharts, setListCharts] = useState<IChartData[]>([]);

  const scrollIntoView = () => {
    detailEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  };

  const handleSave = async (chart_json: IChartjs, sql_result: string | string[], table_json?: ITableData) => {
    try {
      if (!userId) return;
      await saveChart({
        project_id: userId,
        sql_result,
        chart_json,
        table_json
      });
      message.success("Save chart successfully!");
    } catch (error) {
      message.error("Save chart failed!");
    }
  };

  useEffect(() => {
    getChartsData();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      scrollIntoView();
    }, 1500);
  }, [newContent]);

  const getChartsData = async () => {
    try {
      if (!userId) return;
      const res = await getCharts(userId);
      setListCharts(res);
      console.log("chart", res);
    } catch (error) {
      console.log("=========", error);
    }
  };

  return (
    <div className="detail-conversation">
      <div className="title-area">
        <p className="title"></p>
      </div>
      <div>
        {newContent?.map((item, index) => (
          <div style={{ marginBottom: "30px" }} key={index}>
            <ItemChart
              table_json={item?.table_json}
              chartjs_json={item.chartjs_json}
              sql_result={item.sql_result}
              callBack={handleSave}
              icon={
                <Tooltip
                  title="Save"
                  mouseEnterDelay={0.5}
                  mouseLeaveDelay={0.2}
                >
                  <SaveOutlined />
                </Tooltip>
              }
            />
          </div>
        ))}
        <div ref={detailEndRef} />
      </div>
    </div>
  );
};
