import {
  IDbInfor,
  IDbSchema,
  IParamProjectSetting,
  ITableInfor,
} from "types/project";
import { axiosNoAuthInstance } from "utils/axios";

// const url =
//   "https://user-service.happyforest-0c743beb.southeastasia.azurecontainerapps.io";

  const url = process.env.REACT_APP_API_USER

export async function testConnection(item: IDbInfor) {
  const endpoint = `${url}/database/test_connection`;

  const response = await axiosNoAuthInstance.post(endpoint, item);
  const data = response?.data?.message || [];

  return data;
}

export async function createDataSource(item: IDbInfor, userId: string) {
  const endpoint = `${url}/user_settings/database/${userId}`;

  const response = await axiosNoAuthInstance.post(endpoint, item);
  const data = response?.data?.message || [];

  return data;
}

export async function getDataSourceInfo(userId: string) {
  const endpoint = `${url}/user_settings/database/${userId}`;

  const response = await axiosNoAuthInstance.get(endpoint);

  return response.data as IDbInfor;
}

export async function getProjectSetting(userId: string) {
  const endpoint = `${url}/user_settings/projects/${userId}`;

  const response = await axiosNoAuthInstance.get(endpoint);

  return response.data as IParamProjectSetting;
}

export async function deleteProjectSetting(userId: string) {
  const endpoint = `${url}/user_settings/projects/${userId}`;

  const response = await axiosNoAuthInstance.delete(endpoint);

  return response.data;
}


export async function getDbSchema(user_id: string, table_name?: string) {
  const endpoint = `${url}/database/schema`;

  const response = await axiosNoAuthInstance.post(endpoint, {
    user_id,
    table_name,
  });

  return response.data.data as IDbSchema[];
}

export async function createProjectSetting(
  data: IParamProjectSetting,
  user_id: string
) {
  const endpoint = `${url}/user_settings/projects/${user_id}`;

  const response = await axiosNoAuthInstance.post(endpoint, {
    project_id: user_id,
    ...data,
  });

  return response.data.data as IDbSchema[];
}
