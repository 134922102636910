// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.left-handle {
  width: 2px;
  height: 2px;
  min-width: 2px;
  left: 0;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.right-handle {
  width: 2px;
  height: 2px;
  min-width: 2px;
  right: 0;
  top: 50%;
  transform: translateX(50%) translateY(-50%);
}
`, "",{"version":3,"sources":["webpack://./src/pages/Visualizer/style/handle.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,WAAW;EACX,cAAc;EACd,OAAO;EACP,QAAQ;EACR,4CAA4C;AAC9C;;AAEA;EACE,UAAU;EACV,WAAW;EACX,cAAc;EACd,QAAQ;EACR,QAAQ;EACR,2CAA2C;AAC7C","sourcesContent":[".left-handle {\r\n  width: 2px;\r\n  height: 2px;\r\n  min-width: 2px;\r\n  left: 0;\r\n  top: 50%;\r\n  transform: translateX(-50%) translateY(-50%);\r\n}\r\n\r\n.right-handle {\r\n  width: 2px;\r\n  height: 2px;\r\n  min-width: 2px;\r\n  right: 0;\r\n  top: 50%;\r\n  transform: translateX(50%) translateY(-50%);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
