import { Button, Tooltip } from "antd";
import { IDataQualityReport } from "types/dataQuality";
import { DataItemWrap } from "../style";
import { useState } from "react";
import {
  DownOutlined,
  InfoCircleOutlined,
  UpOutlined,
} from "@ant-design/icons";

export const DataItem = ({ item }: { item: IDataQualityReport }) => {
  const [isColapse, setIsCollapse] = useState(false);
  return (
    <DataItemWrap>
      <div className="col">
        <span className="col-name">{item?.column_name}</span>
        <span className="total-errors">{item?.total_errors} errors</span>
        <span className="action">
          <Button
            onClick={() => setIsCollapse(!isColapse)}
            icon={isColapse ? <UpOutlined /> : <DownOutlined />}
          ></Button>
        </span>
      </div>
      {isColapse && <div className="analysis">
        <div style={{fontWeight: 'bold'}}>#</div>
        <div style={{fontWeight: 'bold'}}>Rule</div>
        <div style={{fontWeight: 'bold'}}>Total</div>
        <div style={{fontWeight: 'bold'}}>Abnormal</div>
        <div style={{fontWeight: 'bold'}}>Percentage</div>
      </div>}
      {item?.analysis?.map(
        (analysis, index) =>
          isColapse && (
            <div className="analysis">
              <div>{index + 1}</div>
              <div>
                <span style={{marginRight: '10px'}}>{analysis?.rule_name}</span>
                <Tooltip title={analysis?.rule_description}>
                  <InfoCircleOutlined />
                </Tooltip>
              </div>
              <div>{analysis?.total_count}</div>
              <div>
                {analysis?.abnormal_count}
              </div>
              <div>
                {analysis?.abnormal_percentage > 0
                  ? analysis?.abnormal_percentage.toFixed(2)
                  : 0}
                %
              </div>
            </div>
          )
      )}
    </DataItemWrap>
  );
};
