import { REACTION } from "types/chatbot";
import {
  ChatBotMessageReceive,
  ChatBotMessageSend,
} from "./styled";
import chatbotLogo from "assets/images/chatbot-logo.png";
import { memo, useState } from "react";
// import {
//   IconLikeLine,
//   IconLikeFill,
//   IconDislikeLine,
//   IconDislikeFill,
// } from "assets/svg";
import ReactMarkdown, { Components } from "react-markdown";
import { IChat2ChartInfor } from "types/chat2Chart";
import remarkGfm from "remark-gfm";

interface Props {
  index: number;
  isShowLogo: boolean;
  chatMsg: IChat2ChartInfor;
  isDraggable?: boolean;
  isMarkdownContent?: boolean;
  onDragStart?: (e: any, des: string, img: string, msgId: string) => void;
}

const components: Components = {
  a: ({
    node,
    children,
    href,
    title,
    ...props
  }) => {
    return <a href={href} title={title} children={children} {...props} target="_blank" rel="noreferrer"></a>;
  },
};

const ChatMsg = ({
  chatMsg,
  isShowLogo,
  index,
  isDraggable,
  isMarkdownContent = false,
  onDragStart,
}: Props) => {
  const [reaction, setReaction] = useState<REACTION>(
    chatMsg?.reaction || REACTION.none
  );
  const userLogin = 'user';

  // const handleReaction = (reactionType: REACTION) => {
  //   try {
  //     setReaction(reactionType);
  //     reactionBuddyMsg({
  //       id: chatMsg?.id?.toString() || "",
  //       reaction: reactionType,
  //     });
  //   } catch (error) {}
  // };

  const handleDragStart = (e: any) => {
    if (
      !!isDraggable &&
      chatMsg.chart_url &&
      chatMsg.message_type === "internal_info" &&
      onDragStart
    ) {
      onDragStart &&
        onDragStart(
          e,
          chatMsg?.content || "",
          chatMsg?.chart_url || "",
          chatMsg?.id || "1"
        );
    }
  };

  const encodeUrl = (url: string) => {
    return encodeURI(url);
  };

  const encodeMarkdownLinks = (markdown: string) => {
    console.log(markdown)
    const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
    return markdown.replace(linkRegex, (match, text, url) => {
      const encodedUrl = encodeUrl(url);
      return `[${text}](${encodedUrl})`;
    });
  };

  return chatMsg?.role !== 'human' ? (
    <ChatBotMessageReceive key={chatMsg.id}>
      <>
        <div style={{ width: "32px" }}>
          {isShowLogo && (
            <img
              src={chatbotLogo}
              alt="chatbot logo"
              width={"32px"}
              height={"32px"}
            />
          )}
        </div>
        <div className="content">
          {isMarkdownContent ? (
            <div className="markdown">
              <ReactMarkdown components={components} remarkPlugins={[remarkGfm]}>
                {encodeMarkdownLinks(
                  chatMsg?.content
                )}
              </ReactMarkdown>
            </div>
          ) : (
            <div
              dangerouslySetInnerHTML={{ __html: chatMsg.content }}
              draggable={
                !!isDraggable &&
                !!chatMsg.chart_url &&
                chatMsg.message_type === "internal_info"
              }
              unselectable="on"
              // this is a hack for firefox
              // Firefox requires some kind of initialization
              // which we can do by adding this attribute
              // @see https://bugzilla.mozilla.org/show_bug.cgi?id=568313
              onDragStart={handleDragStart}
            ></div>
          )}
          {/* <MsgReaction>
            {reaction === "none" ? (
              <div className="pick-reaction">
                <div
                  onClick={() => handleReaction(REACTION.like)}
                  className="like-icon"
                >
                  <span>
                    <IconLikeLine />
                  </span>
                  <span>
                    <IconLikeFill />
                  </span>
                </div>
                <div
                  onClick={() => handleReaction(REACTION.dislike)}
                  className="dislike-icon"
                >
                  <span>
                    <IconDislikeLine />
                  </span>
                  <span>
                    <IconDislikeFill />
                  </span>
                </div>
              </div>
            ) : (
              <div className="result-reaction">
                {reaction === "like" ? <IconLikeFill /> : <IconDislikeFill />}
              </div>
            )}
          </MsgReaction> */}
        </div>
      </>
    </ChatBotMessageReceive>
  ) : (
    <ChatBotMessageSend key={index}>
      <div
        className="content"
      >{chatMsg.content}</div>
      {chatMsg?.isError && <p className="error">Not Delivered</p>}
    </ChatBotMessageSend>
  );
};

export default memo(ChatMsg);
