import { ChartData, Point } from "chart.js";
import { DoughnutChart } from "components/Chart/DoughnutChart";
import { Bar, Chart } from "react-chartjs-2";
import { EChartType, EViewType, IChartjs } from "types/chat2Insight";
import { ItemWrapper } from "./style";
import { LineChart } from "components/Chart/LineChart";
import { ScatterChart } from "components/Chart/ScatterChart";
import { MixedChart } from "components/Chart";
import { BarChartOutlined, TableOutlined } from "@ant-design/icons";
import { useState } from "react";
import { TableData } from "./components";
import { ITableData } from "types/table";
import { Image } from "antd";

interface Props {
  data?: {
    title: string;
    value: number;
  };
  image?: string;
  table_json?: ITableData;
  chartjs_json: IChartjs;
  sql_result: string | string[];
  callBack?: (
    chartjs_json: IChartjs,
    sql_result: string | string[],
    table_json?: ITableData
  ) => void;
  icon?: React.ReactNode;
}

export function ItemChart({
  data,
  image,
  table_json,
  chartjs_json,
  callBack,
  icon,
  sql_result,
}: Props) {
  const [selected, setSelected] = useState<EViewType>(EViewType.Chart);

  console.log("table_json", table_json);
  return (
    <ItemWrapper>
      {image ? (
        <div
          style={{
            display: "flex",
            height: "100%",
            alignItems: "center",
          }}
        >
          <Image
            src={image}
            style={{ pointerEvents: "none" }}
            preview={false}
          />
        </div>
      ) : data ? (
        <>
          <div
            style={{
              display: "flex",
              height: "100%",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                fontSize: "67px",
                fontWeight: "bold",
                marginBottom: "25px",
                color: "#3fa684",
              }}
            >
              {data?.value}
            </p>
            <p
              style={{
                fontSize: "30px",
                fontWeight: "bold",
              }}>{data?.title}</p>
          </div>
        </>
      ) : (
        <>
          <div className="chart-title">{chartjs_json?.title}</div>
          <span
            onClick={() =>
              callBack && callBack(chartjs_json, sql_result, table_json)
            }
            className="remove"
          >
            {icon}
          </span>
          <div style={{ textAlign: "center", margin: "10px 0" }}>
            <span
              onClick={() => setSelected(EViewType.Chart)}
              className="selected-view"
              style={{
                backgroundColor:
                  selected === EViewType.Chart ? "#c4efe4" : "transparent",
              }}
            >
              <BarChartOutlined
                style={{
                  fontSize: "24px",
                  color: selected === EViewType.Chart ? "#3fa684" : "#262626",
                }}
              />
            </span>
            {table_json && (
              <span
                onClick={() => setSelected(EViewType.Table)}
                className="selected-view"
                style={{
                  backgroundColor:
                    selected === EViewType.Table ? "#c4efe4" : "transparent",
                }}
              >
                <TableOutlined
                  style={{
                    fontSize: "24px",
                    color: selected === EViewType.Table ? "#3fa684" : "#262626",
                  }}
                />
              </span>
            )}
          </div>
          <div className="chart-content">
            {/* <MixedChart
          type={chartjs_json?.type}
          data={chartjs_json?.data}
          options={chartjs_json?.options}
        /> */}
            {selected === EViewType.Chart && (
              <Chart
                type={chartjs_json?.type}
                data={chartjs_json?.data}
                options={chartjs_json?.options}
              />
            )}
            {selected === EViewType.Table && table_json && (
              <TableData data={table_json} />
            )}
            {/* {chartjs_json?.type === EChartType.Doughnut && (
          <DoughnutChart
            data={
              chartjs_json?.data as ChartData<"doughnut", number[], unknown>
            }
            options={chartjs_json?.options}
          />
        )}
        {chartjs_json?.type === EChartType.Bar && (
          <Bar
            data={chartjs_json?.data as ChartData<"bar", number[], string>}
            options={chartjs_json?.options}
          />
        )}
        {chartjs_json?.type === EChartType.Line && (
          <LineChart
            data={
              chartjs_json?.data as ChartData<
                "line",
                (number | Point | null)[],
                unknown
              >
            }
            options={chartjs_json?.options}
          />
        )}
        {chartjs_json?.type === EChartType.Scatter && (
          <ScatterChart
            data={
              chartjs_json?.data as ChartData<
                "scatter",
                (number | Point | null)[],
                unknown
              >
            }
            options={chartjs_json?.options}
          />
        )} */}
          </div>
        </>
      )}
    </ItemWrapper>
  );
}
